<template lang="html">
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col>
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="currentStep > 1">กรอกข้อมูลผู้ป่วย</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="currentStep > 2">สแกนเอกสาร</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :complete="currentStep > 3">ถ่ายรูป</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4" :complete="currentStep > 4">พิมพ์เอกสาร</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form-pad ref="formPatient" v-model="formData.patient">
                <template v-slot="{data, rules}">
                  <v-card flat>
                    <v-card-title primary-title class="py-1">
                      <v-container pa-0 fluid>
                        <v-row dense>
                          <v-col cols="12" md="4" align-self="center">
                            <span class="headline">ข้อมูลทั่วไป</span>
                          </v-col>
                          <v-col cols="12" md="5" align-self="center">
                            <v-radio-group v-model="data.personIdType" row>
                              <v-radio class="mb-0" label="ผู้ป่วยชาวไทย" :value="1"></v-radio>
                              <v-radio label="ผู้ป่วยชาวต่างชาติ" :value="2"></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-master-field v-model="data.nationality" label="สัญชาติ"
                              :groupKey="$store.getters.getGroupKey('nationality')"
                              :rules="[rules.requireIf(isForeigner)]"
                              :disabled="isThai"
                            ></v-master-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-title>
                    <v-card-text class="py-1">
                      <v-container pa-0 fluid>
                        <v-row dense>
                          <v-col cols="12" md="6" v-if="isThai">
                            <v-text-field v-model="data.personId" label="เลขประจำตัวประชาชน"
                              :rules="[rules.require()]"
                              :append-outer-icon="(!config.isLoadDataFromIDCard && !data.personIdVerified) ? 'get_app' : null"
                              @click:append-outer="getDataIDCard"
                              :loading="config.isLoadDataFromIDCard"
                              :readonly="data.personIdVerified"
                              autofocus
                            >
                              <template v-slot:append>
                                <v-chip color="teal" text-color="white" v-if="data.personIdVerified">
                                  <v-avatar><v-icon>check_circle</v-icon></v-avatar>
                                  Confirmed
                                </v-chip>
                              </template>
                            </v-text-field>

                          </v-col>
                          <v-col cols="12" md="6" v-if="isForeigner">
                            <v-row dense>
                              <v-col cols="8">
                                <v-text-field v-model="data.personId" label="Passport No."
                                  :rules="[rules.require()]"
                                  auto-focus
                                >
                                  <template v-slot:append>
                                    <v-chip color="teal" text-color="white" v-if="data.personIdVerified">
                                      <v-avatar><v-icon>check_circle</v-icon></v-avatar>
                                      Confirmed
                                    </v-chip>
                                  </template>
                                </v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-date-field v-model="data.personIdDetail.passportExpiryDate" append-icon="event" label="Passport expiry date" :rules="[rules.require()]"></v-date-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col md="6">
                            <template v-if="isForeigner">
                              <v-row dense>
                                <v-col cols="8">
                                  <v-text-field v-model="data.personIdDetail.visaNo" label="Visa No."></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <v-date-field v-model="data.personIdDetail.visaExpiryDate" append-icon="event" label="Visa expiry date"></v-date-field>
                                </v-col>
                              </v-row>
                            </template>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-patient-name v-model="formData.name[0]" name-type="TH" :disabled="isForeigner" :readonly="data.personIdVerified" required></v-patient-name>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-patient-name v-model="formData.name[1]" name-type="EN" :readonly="data.personIdVerified" required english></v-patient-name>
                          </v-col>
                          <v-col cols="6" md="2">
                            <v-date-field v-model="data.dateOfBirth" prepend-icon="event" label="วัน-เดือน-ปี เกิด"
                              :rules="[rules.require()]"
                              birth-date
                            ></v-date-field>
                          </v-col>
                          <v-col cols="6" md="2">
                            <v-radio-group v-model="data.sex" row label="เพศ" :rules="[rules.require()]">
                              <v-spacer></v-spacer>
                              <v-radio class="mr-1" label="ชาย" value="1"></v-radio>
                              <v-radio label="หญิง" value="2"></v-radio>
                              <v-spacer></v-spacer>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="6" md="2">
                            <v-master-field v-model="data.maritalStatus" label="สถานะภาพ"
                              :groupKey="$store.getters.getGroupKey('maritalStatus')"
                              clearable
                            ></v-master-field>
                          </v-col>
                          <v-col cols="6" md="3">
                            <v-master-field v-model="data.religion" label="ศาสนา"
                              :groupKey="$store.getters.getGroupKey('religion')"
                              clearable
                            ></v-master-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-master-field v-model="data.occupation" label="อาชีพ"
                              :groupKey="$store.getters.getGroupKey('occupation')"
                              clearable
                            ></v-master-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.paternalName" label="ชื่อบิดา"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.maternalName" label="ชื่อมารดา"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.spouseName" label="ชื่อคู่สมรส"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.primaryMobileNo" label="เบอร์โทรศัพท์มือถือ" :rules="[rules.require()]"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.primaryTelephoneNo" label="เบอร์โทรศัพท์บ้าน"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="data.primaryEmail" label="Email"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                  <v-card flat>
                    <v-card-title primary-title class="py-1">
                      <span class="headline">ผู้ติดต่อกรณีฉุกเฉิน</span>
                    </v-card-title>
                    <v-card-text class="py-1">
                      <v-container pa-0 fluid>
                        <v-row>
                          <v-col cols="12">
                            <v-relative v-model="formData.relative[0]" required></v-relative>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                  <v-card flat v-if="isThai">
                    <v-card-title primary-title class="py-1">
                      <span class="headline">ที่อยู่ตามบัตรประชาชน</span>
                    </v-card-title>
                    <v-card-text class="py-1">
                      <v-address v-model="formData.address[1]" address-type="census" @input="copyAddress" required key="census"></v-address>
                    </v-card-text>
                  </v-card>
                  <v-card flat>
                    <v-card-title primary-title class="py-1">
                      <span class="headline">ที่อยู่ปัจจุบัน&nbsp;</span><span class="headline" v-if="isForeigner">(ในประเทศไทย)</span>
                      <v-checkbox label="ตามบัตรประชาชน" v-model="config.isContactSameAsCensus" v-if="isThai"></v-checkbox>
                    </v-card-title>
                    <v-card-text class="py-1">
                      <v-address v-model="formData.address[0]"
                        address-type="contact"
                        :disabled="config.isContactSameAsCensus && isThai"
                        required key="contact"
                      ></v-address>
                    </v-card-text>
                  </v-card>
                  <v-card flat v-if="isForeigner">
                    <v-card-title primary-title class="py-1">
                      <span class="headline">ที่อยู่ตามภูมิลำเนา</span>
                    </v-card-title>
                    <v-card-text class="py-1">
                      <v-address v-model="formData.address[1]" address-type="foreign" foreign-address key="foreign"></v-address>
                    </v-card-text>
                  </v-card>
                </template>
              </v-form-pad>
              <v-btn color="primary" @click.native="savePatientAndNext" :loading="config.isSavingPatient">บันทึกข้อมูล</v-btn>&nbsp;
              <v-btn text @click.native="reset">ล้างข้อมูล</v-btn>&nbsp;
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card flat>
                <v-card-text>
                  <div align="center">
                    <span class="display-2 font-weight-bold">เลขประจำตัวผู้ป่วย {{ newPatientHN }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-card-title primary-title class="py-1">
                  <span class="headline">สแกนเอกสาร</span>
                </v-card-title>
                <v-card-text class="py-1" v-if="newPatientHN">
                  <v-document-scan title="Personal Documents" :hn="newPatientHN" defaultCategory="100" :availableCategory="['100','101','102','103','104','109']"></v-document-scan>
                </v-card-text>
              </v-card>
              <v-btn color="primary" @click.native="saveDocumentAndNext" :loading="config.isSavingPatient">ต่อไป</v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card flat>
                <v-card-text>
                  <div align="center">
                    <span class="display-2 font-weight-bold">เลขประจำตัวผู้ป่วย {{ newPatientHN }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-card-title primary-title class="py-1">
                  <span class="headline">ถ่ายรูป</span>
                </v-card-title>
                <v-card-text class="py-1" v-if="newPatientHN">
                  <v-patient-photo-list :hn="newPatientHN"></v-patient-photo-list>
                </v-card-text>
              </v-card>
              <v-btn color="primary" @click.native="savePhotoAndNext" :loading="config.isSavingPatient">ต่อไป</v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card flat>
                <v-card-text>
                  <div align="center">
                    <span class="display-2 font-weight-bold">เลขประจำตัวผู้ป่วย {{ newPatientHN }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card flat>
                <v-card-title primary-title class="py-1">
                  <span class="headline">พิมพ์เอกสาร</span>
                </v-card-title>
                <v-card-text class="py-1" v-if="newPatientHN">
                  <v-document-print-list :hn="newPatientHN"></v-document-print-list>
                </v-card-text>
              </v-card>
              <v-btn color="primary" @click.native="finish" :loading="config.isSavingPatient">เสร็จสิ้น</v-btn>&nbsp;
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
import { isUndefined,isEmpty,isArray,cloneDeep,merge } from 'lodash'
import getIDCard from '@/modules/getIDCard'

export default {
  data: ()=>({
    currentStep: 1,
    formData: {
      patient: {
        personIdType: 1,
        personIdVerified: false,
        personIdDetail: {},
      },
      name: [],
      address: [],
      relative: [],
    },
    config: {
      isContactSameAsCensus: true,
      isLoadingIDCard: false,
      isLoadDataFromIDCard: false,
      isSavingPatient: false,
    },
    newPatientHN: null,
  }),
  watch: {
    'config.isContactSameAsCensus': function() {
      this.copyAddress()
    },
    isThai: function(newValue) {
      if (newValue) {
        this.formData.patient.nationality = undefined
      }
    },
    isForeigner: function(newValue) {
      if (newValue) {
        this.$set(this.formData.name,0,{})
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.formPatient.validate()
    },
    reset() {
      this.$refs.formPatient.reset()
      this.formData = {
        patient: {
          personIdType: 1,
          personIdDetail: {},
        },
        name: [],
        address: [],
        relative: [],
      }
      this.config = {
        isContactSameAsCensus: true,
        isLoadingIDCard: false,
        isLoadDataFromIDCard: false,
      }
      this.newPatientHN = null
    },
    copyAddress() {
      if (this.config.isContactSameAsCensus && !this.isForeigner) {
        this.formData.address[0] = cloneDeep(this.formData.address[1])
      }
    },
    savePatientAndNext() {
      this.config.isSavingPatient = true
      if (this.validate()) {
        this.$confirm('ต้องการบันทึกข้อมูลและสร้างผู้ป่วยใหม่').then((res)=>{
          if (res) {
            let loading = this.$loading('Saving')
            api.post(['Patient','PatientController','createPatient'],this.formData).then((returnData)=> {
              if (returnData.success) {
                this.newPatientHN = returnData.data.patient[0].hn
                this.currentStep = 2
              } else {
                returnData.errorTexts.forEach((item)=>{
                  this.$store.dispatch('addAlertItem',{
                    alertText: item.errorText,
                    alertType: 'error'
                  })
                })
              }
            }).finally(()=>{
              loading.done()
              this.config.isSavingPatient = false
            })
          } else {
            this.config.isSavingPatient = false
          }
        })
      } else {
        this.config.isSavingPatient = false
      }
    },
    saveDocumentAndNext() {
      this.currentStep = 3
    },
    savePhotoAndNext() {
      this.currentStep = 4
    },
    finish() {
      this.reset()
      this.currentStep = 1
    },
    getDataIDCard() {
      this.config.isLoadDataFromIDCard = true
      getIDCard().then((cardData)=>{
        if (!isUndefined(cardData.address) && isArray(cardData.address)) cardData.address.unshift({})
        this.formData = merge({},this.formData,cardData)
        if (this.config.isContactSameAsCensus && !this.isForeigner) {
          this.formData.address[0] = cloneDeep(this.formData.address[1])
        }
      })
      .catch((e)=>void e)
      .finally(()=>{
        this.config.isLoadDataFromIDCard = false
      })
    },
  },
  computed: {
    isForeigner() {
      return this.formData.patient.personIdType!=1
    },
    isThai() {
      return this.formData.patient.personIdType==1
    },
    isPersonIdDetail() {
      return !isEmpty(this.formData.patient.personIdDetail)
    }
  }
}
</script>

<style lang="css">
</style>
